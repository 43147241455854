<template>
    <div class="bannerContent" v-html="txt"></div>
</template>

<script>
    export default {
        name: "BannerContent.vue"
    }
</script>

<style scoped>
    .bannerContent{
        font-size: 26px;
        text-align: left;
        width: 710px;
        letter-spacing: 2px;
    }
</style>
